.heading {
    color: white;
    line-height: 32px;
    font-size: 1.5rem;
    font-weight: 700 !important;
}

.edit-button {
    font-size: 1rem;
    font-weight: 800 !important;
    border: 1px solid black !important;
    color: black !important;
    height: 38px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    text-decoration: none;
    border-radius: 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center; 
    padding: 1rem !important;
}

.send-to-button {
    font-size: 1rem;
    font-weight: 800;
    color: white !important;
    background: #008385 !important;
    height: 38px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    text-decoration: none;
    border-radius: 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center; 
    padding: 1rem !important;
}

.table-cell {
    font-size: 1.125rem !important;
    padding: 1rem !important;
    width: 12%;
    text-align: center !important;
}

.cell-bold {
    font-weight: 800 !important;
    background: #F6F6F6;
}

.total-table-cell {
    background: #F8D061;
    font-weight: 700 !important;
    font-size: 1.125rem !important;
    padding: 0.5rem 1.2rem !important;
    text-align: center;
    border: none !important;
}

.border-t {
    border-top: 2px solid black !important;
}

.border-r {
    border-right: 1px solid black !important;
}