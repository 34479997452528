.tablesideRow {
  border: 1px solid #a3a3a3;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.customMenu {
  border: 1px solid #a3a3a3;
  display: flex;
  align-items: center;
  width: 90%;
  background-color: #55c2c5 !important;
}

.title {
  height: fit-content;
  color: white;
}

.menuDesc {
  background-color: white !important;
}

.text-padding {
  padding: 10px;
}

.file-text {
  word-break: break-all;
}
