.top {
  margin-top: 10px !important;
}
.contactUsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.contactInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333333;
}

.contactInfoInnerWrapper {
  display: flex;
  width: 450px;
}

.contactUsIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 91px;
  width: 91px;
  margin: 10px 20px 10px 10px;
  font-size: 35px;
  border-radius: 50%;
  background-color: rgba(0, 188, 190, 0.18);
  color: #fcfcfc;
}

.contactInfoMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contactInfoMainLabel {
  font-size: 20px;
  font-weight: bold;
  color: var(--tealColor);
}

.contactInfoGroup {
  display: flex;
}

.contactInfoLabel {
  display: inline-block;
  width: 75px;
  text-align: left;
  margin-bottom: 5px;
  margin-right: 10px;
  font-weight: bold;
}

.contactInfoValue {
  display: inline-block;
  width: 150px;
  text-align: left;
  margin-bottom: 5px;
}

.contactInputWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333333;
}

.contactInputHeader {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.contactUsBlurb {
  display: flex;
  justify-content: left;
  font-size: 18px;
  margin-bottom: 30px;
  width: 500px;
}

.contactInputWrapper label {
  display: flex;
  flex-direction: column;
}

.contactTextPairName {
  display: inline-block;
  width: 300px;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}

.contactUsInput {
  width: 400px;
  margin-bottom: 10px !important;
  padding: 8px !important;
  font-family: "Nunito Sans", sans-serif;
  border-radius: 8px !important;
}

.contactTextArea {
  width: 400px;
  height: 100px;
  padding: 8px;
  font-family: "Nunito Sans", sans-serif;
  border: 1px solid #cbcbcb;
  border-radius: 8px;
  resize: none;
  outline: none;
}

.contactSubmitButtonActive {
  background-color: #55c2c5;
  border: 1px solid #55c2c5;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  width: 350px;
  margin-top: 30px;
  padding: 5px 30px;
  max-height: 30px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.contactSubmitButtonDisabled {
  background-color: grey;
  border: 1px solid grey;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  width: 350px;
  margin-top: 30px;
  padding: 5px 30px;
  max-height: 30px;
  text-align: center;
  font-size: 16px;
  cursor: auto;
}
