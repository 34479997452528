.color-picker {
  border: 1px solid grey;
  padding: 5px;
  width: 500px;
  padding: 5px;
}

.color-picker-box {
  background: #eff1f3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 5px;
  height: 40px;
}
.color-box {
  width: 25px;
  height: 20px;
  border: 1px solid black;
}

.color-text {
  background-color: white;
  color: black;
  width: 400px;
  text-align: center;
}

.cover {
  position: relative;
  top: "0px";
  right: "0px";
  bottom: "0px";
  left: "0px";
}

.cover .close {
  position: absolute;
  left: 190px;
  top: 5px;
  cursor: pointer;
}

.cover .sketch-picker {
  margin-top: 20px;
  padding-top: 30px !important;
}

.popover {
  position: "absolute";
  z-index: "2";
}
