.table-header {
  background-color: white;
  text-align: center;
  margin-left: 30px !important;
  padding: 2px 8px;
  border-top-right-radius: 9px !important;
  border-top-left-radius: 9px !important;
}

.header {
  margin-left: 30px !important;
}
