.baseLink {
  /* margin: 8px 0; */
  color: #808080;
  text-decoration: none;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: var(--left-sidebar-font-size) !important;
  line-height: var(--left-sidebar-line-height) !important;
  /* padding-left: 8px; */
  display: flex !important;
  align-items: center;
}

.baseLink:hover {
  color: #333333;
}

.baseLink.active {
  color: #333333;
}

.baseLink:hover .sidebarIcon {
  background: -moz-linear-gradient(var(--orangeGradient));
  background: -webkit-linear-gradient(var(--orangeGradient));
  background: linear-gradient(var(--orangeGradient));
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.baseLink.active .sidebarIcon {
  background: -moz-linear-gradient(var(--orangeGradient));
  background: -webkit-linear-gradient(var(--orangeGradient));
  background: linear-gradient(var(--orangeGradient));
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.sidebarIcon {
  font-style: normal;
  font-weight: 900;
  font-size: var(--left-sidebar-font-size);
  line-height: var(--left-sidebar-line-height);
  margin-right: 18px;
  color: #808080;
}
