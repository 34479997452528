.MenuBulkHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.MenuBulkHeaderLeft {
    font-size: 18px;
}

.MenuMainTitle {
    font-weight: bold;
    margin-bottom: 20px;
}

.MenuBulkHeaderRight {
    width: 40%;
    border: 1px solid #EDEDED;
    padding: 5px 5px 0px 5px;
}

.MenuBulkHeaderRightRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.MenuBulkBlueSample {
    height: 30px;
    width: 90px;
    background-color: #AEE4E5;
}

.MenuBulkYellowSample {
    height: 30px;
    width: 90px;
    background-color: #FFEFB6;
}