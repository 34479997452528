.fullIngredientBox {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 5px;
    padding: 10px;
    width: 100%;
    border-radius: var(--borderRadius);
    border: 1px solid lightgrey;
}

.imageBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    padding: 10px;
    border-radius: var(--borderRadius);
    border: 1px solid lightgrey;
}

.instructionBox {
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding: 10px;
    height: 100%;
    border-radius: var(--borderRadius);
    border: 1px solid lightgrey;
}

img {
    max-height: 400px;
    max-width: 400px;
}

.recipeDetailLabel {
    font-size: 16px;
    font-weight: 700;
}

.recipeDetailValue {
    font-size: 16px;
    font-weight: normal;
}