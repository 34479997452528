.visitReportsAccordian {
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin-top: 4px;
}

.loader {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
}
.headerBox {
  display: flex !important;
  justify-content: space-between !important;
}

.dataBox {
  overflow: auto !important;
  border: 1px solid #cccccc !important;
  /* height: 45vh !important; */
}

.consultDataBox {
  overflow: auto !important;
  border: 1px solid #cccccc !important;
  height: auto !important;
}
.configBox {
  display: flex !important;
  align-items: center !important;
}
.gridStyling {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding-left: 10px !important;
}

.visitReportAttachments {
  border-radius: 10px !important;
}

.report-btn {
  text-transform: none !important;
  color: white !important;
}

.itemBox {
  max-height: 40px !important;
  min-height: 40px !important;
}
