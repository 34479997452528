* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

:root {
  /* Colors */
  --whiteColor: #f8f9fa;
  --whiteColorOpacity: rgba(255, 255, 255, 0.5);
  --tealColor: #008385;
  --redColor: #ff3f28;
  --yellowColor: #fdd844;
  --greenColor: #14ae5c;
  --grayColor: #dcdcdc;
  --lightGrayColor: #747474;

  --darkGrayColor: #505050;
  --orangeGradient: 180deg, #fec92c 0%, var(--redColor) 100%;
  --orangeColor: #fec92c;
  --backgroundColorLight: white;

  /* Box Shadows */
  --tealBoxShadow: 9px 10px 24px -10px rgba(85, 194, 197, 0.27);
  --darkBoxShadow: 3px 4px 28px -8px rgba(0, 0, 0, 0.25);

  /* Border */
  --borderRadius: 10px;
  --fieldBorderRadius: 5px;
  --whiteBorder: 2px solid var(--whiteColor);
  --border: 1px solid var(--tealColor);
  --border2: 2px solid var(--tealColor);
  --border3: 3px solid var(--tealColor);

  /* Font Settings */
  --fontFamily: "Nunito Sans", sans-serif;
  --left-sidebar-font-size: 1.05em;
  --left-sidebar-line-height: 1.05em;
  --heading-font-size: 1.3em;
}

#root {
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: var(--whiteColor);
}

#sidebarSlider {
  position: absolute;
  height: 1.2em;
  width: 18px;
  background: linear-gradient(var(--orangeGradient));
  left: -12px;
  transition: 0.5s ease-in-out;
  border-radius: var(--fieldBorderRadius);
  top: 214px;
}

#appHeader {
  box-shadow: none !important;
  min-height: 4px;
}

.App {
  width: 100%;
}

@media (max-width: 550px) {
  .App {
    width: 100%;
  }
}

.colorSuccess {
  color: var(--greenColor);
}

.colorError {
  color: var(--redColor);
}

body {
  height: 100%;
  font-family: var(--fontFamily);
}

.page-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  margin: 0px 30px;
  border-radius: var(--borderRadius);
}

.page-body.mealCard {
  background-color: var(--backgroundColorLight);
  padding: 10px;
  margin-top: 0 !important;
  border-radius: 0px 10px 10px 10px !important;
}

.baseButton {
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 0.9em !important;
  line-height: 19px !important;
  /* identical to box height */
  padding: 0.4rem 1.2em !important;
  text-transform: none !important;
  border-radius: 5px !important;
}

/* .baseButton .MuiButton-endIcon {
  margin-left: 28px;
} */

#right {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0px !important;
}

.tealColor {
  color: var(--tealColor);
}

.redColor {
  color: var(--redColor);
}

.greenColor {
  color: var(--greenColor);
}

.topleft {
  min-width: 120px;
  flex-shrink: 0;
}

.column {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.MuiStack-root {
  padding-top: 0px !important;
  overflow: visible !important;
}

.MuiTab-icon {
  margin-bottom: 1px !important;
}
