.baseLogo {
  margin: 10px;
  width: 80%;
}

.baseLi {
  display: flex;
  align-items: center;
  /* margin: 3px; */
}
