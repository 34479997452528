.passwordModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--whiteColor);
    box-shadow: 24;
    width: 40%!important;
    border-radius: 10px;
    border: none;
    border-top: 32px var(--yellowColor) solid;
    padding-top: 0!important;
    padding: 4;
}

.passwordModalSuccess{
    border-top: 32px var(--tealColor) solid !important;
}

.passwordActions{
    padding:10px;
    padding-right:24px;
    display: flex; 
    justify-content: flex-end;
}

.modalContent{
    display: flex;
    align-items: center;
}

.modalIcon{
    font-size: 90px;
    color: var(--yellowColor) ;
    margin-right: 24px; 
}

.modalIconTeal{
    font-size: 90px;
    color: var(--tealColor) ;
    margin-right: 24px; 
}

.modalTitle{
    line-height: 0;
}