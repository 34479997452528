.deleteWarningBox {
  border: 1px solid #eb3223;
  font-weight: bold;
  color: var(--redColor);
  padding-bottom: 5px;
}

.deleteWarningBoxText {
  margin: 3px;
}
