.authenticatedHomePage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0px;
}

.unauthenticatedHomePage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px;
}

.logoHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.centerLogo {
    width: 300px;
}

.loginMessage {
    margin: 10px;
    font-size: 24px;
    font-weight: bold;
}

.loginMessage2 {
    margin: 2px;
    font-size: 14px;
}

.generalSection {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.generalSectionButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 30px;
    width: 250px;
    height: 250px;
    margin: 20px;
    padding: 47px;
    background-size: 250px 250px;
    text-decoration: none;
}

.corpDocumentSection {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.corpDocumentSectionButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 30px;
    width: 540px;
    height: 200px;
    margin: 20px;
    background-size: 540px 200px;
    text-decoration: none;
}

.sectionHeader {
    margin: 40px 20px 20px 20px;
    font-size: 24px;
    font-weight: 800;
}