.importVendorPriceFormWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.importantInfoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55%;
    padding: 10px;
    margin-bottom: 20px;
    color: white;
    background-color: var(--tealColor);
    border-radius: var(--borderRadius);
}

.warningInfoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55%;
    padding: 10px;
    margin-bottom: 20px;
    color: white;
    background-color: var(--redColor);
    border-radius: var(--borderRadius);
}

.importantInfoWrapper p {
    font-size: 24px;
    font-weight: bold;
}

.warningInfoWrapper p {
    font-size: 24px;
    font-weight: bold;
}