.deleteModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--whiteColor);
  box-shadow: 24;
  width: 40% !important;
  border-radius: 10px;
  border: none;
  border-top: 32px var(--redColor) solid;
  padding-top: 0 !important;
  padding: 4;
}

.deleteModalSuccess {
  border-top: 32px var(--tealColor) solid !important;
}

.deleteActions {
  padding: 10px;
  padding-right: 24px;
  display: flex;
  justify-content: flex-end;
}

.modalContent {
  display: flex;
  align-items: center;
}

.deleteIcon {
  font-size: 90px;
  color: var(--redColor);
  margin-right: 24px;
}

.modalIconTeal {
  font-size: 90px;
  color: var(--tealColor);
  margin-right: 24px;
}

.modalTitle {
  line-height: 0;
}

.archivedModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--whiteColor);
  box-shadow: 24;
  width: 35% !important;
  border: none;
  border-radius: 10px;
  /* border-top: 32px #a80000 solid; */
  padding-top: 0 !important;
  padding: 0 !important;
}
