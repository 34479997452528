.faq-Head {
  text-align: left;
  padding: 2px 8px !important;
  border-top-right-radius: 9px !important;
  border-top-left-radius: 9px !important;
  min-width: 30% !important;
}

.FAQ-header {
  background-color: white;
  width: 25%;
  margin-left: 30px !important;
  border-radius: 5px 0px 0px 5px;
  padding: 10px;
}

.FAQ-card {
  width: 95%;
  margin: 20px 0 50px 0;
}

.FAQ-section {
  padding-bottom: 20px;
}

.FAQ-video {
  margin: 10px 0 10px 0;
}

.expand-icon {
  height: 12px;
}

.FAQ-body li {
  margin-left: 50px;
}