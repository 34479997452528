.formModalBody {
  /* position: absolute;
  top: 50%;
  left: 50%;

  height: 100%;
  display: "block";
  
  box-sizing: content-box;*/
  overflow-y: auto;
  overflow: "scroll";
  height: 100%;
  box-sizing: content-box;
  /* margin: 5%; */
}
.formModal {
  position: absolute;
  top: 10%;
  left: 30%;

  /* transform: translate(-50%, -50%); */
  background-color: #ececec;
  box-shadow: 24;
  width: 40% !important;
  border-radius: 0px;
  border: none;
  /* border-top: 32px #008385 solid; */
  padding-top: 0 !important;
  padding: 4;
  /* overflow-y: auto;
  overflow: "scroll"; */
}

.model-title {
  background-color: #008385;
  color: white;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 10px;
}
.model-title .MuiTypography-root {
  font-weight: 700;
  font-size: 24px;
  color: white;
}
.model-title .modalTitle {
  font-weight: 700;
  font-size: 24px;
  color: white;
}

.model-title .material-icons {
  padding: 10px 5px 0 0;
}

.formModalSuccess {
  border-top: 32px var(--tealColor) solid !important;
}

.formActions {
  padding: 10px;
  padding-right: 24px;
  display: flex;
  justify-content: center;
}

.cancelButton {
  background-color: white !important;
  border: 1px solid #1e3c3d !important;
  box-shadow: 0px 2px 4px 0px #00000040 !important;
  color: #1e3c3d !important;
  font-size: 16 !important;
  font-weight: 800 !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
}

.submitButton {
  background-color: #008385 !important;
  box-shadow: 0px 2px 4px 0px #00000040 !important;
  color: white !important;
  font-size: 16 !important;
  font-weight: 800 !important;
  border-radius: 6px !important;
  margin-left: 10px !important;
  text-transform: capitalize !important;
}
.formModalContent {
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
}

.formModalContent .modalTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 27.28px;
}

.formModalContent .MuiInputBase-root {
  background-color: white !important;
  border: 1px solid black;
  box-shadow: 0px 2px 4px 0px #00000040;
  border-radius: 4px;
  margin: 0 0 10px 0;
  height: 45px !important;
}

.formModalContent .MuiAutocomplete-input {
  padding: 0.5px 4px 7.5px 5px !important;
  color: black !important;
}

.formModalContent .MuiInputBase-input {
  color: black !important;
}

.formModalContent input::placeholder {
  color: black !important;
}

.formModalContent .basicInput:focus {
  border: 1px solid black !important;
}

.formModalContent .MuiFormControlLabel-root p {
  font-weight: 600;
}

.formModalContent .MuiFormControlLabel-root svg {
  /* background-color: white !important;

  border: 2px solid black !important; */
  border-radius: 5px;
  box-shadow: 0 2px 1px -2px #00000040;
  height: 25px !important;
  width: 25px !important;
  margin: 5px;
}

.formModalContent .Mui-checked svg {
  fill: black !important;
}

.formModalContent .css-qjkaav-MuiButtonBase-root-MuiCheckbox-root {
  color: black !important;
}
