.css-4jnixx-MuiStack-root {
  padding-top: 0px !important;
  overflow: unset !important;
}

/* .MuiInputBase-root {
  border: 1px solid black;
  box-shadow: 0px 2px 4px 0px #00000040;
}

.MuiFormLabel-root {
  visibility: hidden;
} */
