.baseUserInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--whiteColor);
  /* margin: 20px 50px; */
  margin-right: 8px;
  /* z-index: 200; */
  /* Blue */

  border: var(--border);
  border-radius: 6px;
}

.baseUserIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: #bbb;
  margin: 15px;
}

.baseUserRight {
  font-weight: bold;
}

.baseUserName {
  font-size: 18px;
}

.baseUserCommunity {
  font-size: 12px;
}
