.container {
  /* white */
  background: var(--whiteColor);

  /* sha */
  box-shadow: var(--tealBoxShadow) !important;
  border-radius: var(--borderRadius) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.plateFul-header {
  background-color: white;
  width: 150px;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  padding: 9px 10px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  padding-bottom: 10px;
  margin-right: 5px;
  margin-left: -20px !important;
}

.share-recipe-row {
  display: flex;
  align-items: center;
  height: 80px !important;
  width: 98%;
  padding-bottom: 10px;
}

.share-recipe-row .css-okr9df-MuiGrid-root {
  margin: 0px !important;
}
.share-recipe-btn {
  background-color: "#FFFFFF";
  color: "#55c2c5";
  border: "2px solid #55c2c5";
  width: "20%";
}

.test {
  margin: 0px !important;
}

.baseButton {
  margin-left: 10px !important;
}

.plateMenuButton {
  margin-top: 2px;
  color: var(--yellowColor);
}

.plateMenuButton:focus {
  color: var(--yellowColor) !important;
}

.recipe-desc {
  color: #55c2c5;
  border: 1px solid #55c2c5;
  padding: 5px;
  margin: 5px !important;
  width: 70%;
}

.recipeDetailTab {
  box-shadow: var(--tealBoxShadow) !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 16px !important;
  border-radius: 10px 10px 0px 0px !important;
  background: rgba(203, 221, 221, 0.2) !important;
  padding: 4px 8px !important;
  min-height: 0 !important;
  text-transform: none !important;
}

/* .editIcon {
  background-color: var(--yellowColor);
  color: var(--darkGrayColor);
  padding-bottom: 0px;
  padding-top: 8px;
  border-radius: 4px;
  margin-left: 10px;
}

.editIcon .material-icons {
  line-height: 0px;
  font-size: 20px;
} */

.cellRightAlign {
  justify-content: flex-end !important;
}
