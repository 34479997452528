.basicInput {
  box-sizing: border-box;
  padding: 8px;
  /* margin: 10px 25px 10px 15px; */
  width: 200px;

  /* position: absolute; */
  width: 320px;
  height: 38px;

  /* Blue */

  border: 1px solid var(--tealColor);
  border-radius: var(--borderRadius);
}

.basicInput:focus-within {
  box-shadow: var(--tealBoxShadow);
  border: 2px solid var(--tealColor);
}
