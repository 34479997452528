.adminTabs,
.consultantTabs {
  box-shadow: var(--tealBoxShadow) !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  border-radius: 10px 10px 0px 0px !important;
  /* background: rgba(203, 221, 221, 0.2) !important; */
  padding: 10px 10px !important;
  min-height: 0 !important;
  text-transform: none !important;
  color: black !important;
}
/* .consultantTabs {
    box-shadow: var(--tealBoxShadow) !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 14px !important;
    line-height: 16px !important;
        border-radius: 10px 10px 0px 0px !important;
    background: rgba(203, 221, 221, 0.2) !important;
    padding: 10px 10px !important;
    min-height: 0 !important;
    text-transform: none !important;
} */

.consultantTabs.Mui-selected,
.adminTabs.Mui-selected,
consultantTabs.active {
  /* background: white !important; */
  /* Blue */
  font-size: 0.875rem !important;
  border-radius: 0px !important;
  border-bottom: 2px solid var(--tealColor);
}

.backTab {
  background: var(--tealColor) !important;
  /* Blue */

  box-shadow: var(--tealBoxShadow) !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  border-radius: 10px 10px 0px 0px !important;
  padding: 4px 10px !important;
  min-height: 0 !important;
  text-transform: none !important;
  color: var(--whiteColor) !important;
  flex-direction: row !important;
  align-items: center !important;
}

.backTab span {
  margin-bottom: 0 !important;
  margin-right: 8px !important;
}

.MuiTabs-root {
  min-height: 0 !important;
  margin-top: 20px;
}

.MuiTabs-indicator {
  display: none;
}
