/* MUI Overrides */
.MuiTableHead-root .MuiTableCell-root {
  font-weight: bold;
  font-size: medium;
}

.actionsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.editIcon {
  background-color: var(--yellowColor);
  color: var(--darkGrayColor);
  padding: 6px 6px 0.5px 6px;
  border-radius: 6px;
  margin-left: 20px;
}

.viewIcon {
  background-color: var(--tealColor);
  color: var(--whiteColor);
  padding: 2px 6px;
  padding-top: 4px;
  border-radius: 6px;
  margin-left: 20px;
}

.leftActions {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  /* margin-bottom: 10px;  */
}

.cellRightAlign {
  justify-content: end !important;
}

.cellCenterAlign {
  justify-content: center !important;
}
.cellWithMargin {
  margin-left: 50px;
}
.cellLeftAlign {
  justify-content: start;
}

.dataCells {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  /* identical to box height */

  color: #333333;
}

/* .actionButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
} */

.menuDeleteButton {
  background-color: var(--redColor) !important;
  color: var(--whiteColor) !important;
  /* border-radius: 8px; */
}

.menuButton {
  margin-left: 8px;
  color: var(--yellowColor);
}

.menuButton:focus {
  color: var(--yellowColor) !important;
}

.editButton {
  margin-right: 0px !important;
}

input::placeholder {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* Blue */
  color: var(--tealColor) !important;
  opacity: 1 !important;
}

.newEditButton {
  color: black;
  text-decoration: none;
  font-weight: 800;
  border: 1px solid black;
  border-radius: 8px;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 1rem;
  align-items: center;
  padding: 0px 16px;
  width: 56px;
  height: 30px;
  background-color: white;
}
