.userFacilityTitle{
    padding: 5px 10px;
    background-color: var(--tealColor);
    border-radius: 10px;
    text-align: center;
    box-shadow: var(--tealBoxShadow);
    color: white !important;
}

.leftActions{
    width:100%;
    justify-content: left;
}