.page-body.chatConvo {
  display: flex;
  align-items: center;
  margin: 0px;
  border-radius: 2px;
  overflow: hidden;
  padding: 1.5rem 3rem;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  background: white;

  @media (min-width: 600px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.chatTitle {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 700;
  color: #00BCBE;
}

.page-body.citation {
  display: flex;
  margin: 2px;
  padding: 1.5rem 1rem;
  justify-content: space-between;
  height: 80vh;
  background: white;


}
.chatHeaderMain {
  padding: 0.25rem;
  margin-bottom: 0.75rem;
  font-size: 1.0rem;
  line-height: 2rem;
  font-weight: 700;
  text-align: center;
}

.chatBodyMain {
  overflow: auto;
  padding: 1rem;
  align-self: center; 
  width: 100%;
  height: 90%;  
  border-radius:20px;
  background-color: #ECECEC;
}

.initalButtons {
  display: flex;
  justify-content: flex-end;
  margin: 16px 0px;
}

.chatInputMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius:20px;
}

.chatInputDiv {
  position: relative;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 600px;
  height: 5%;
  background-color: rgb(153, 37, 37);
}

.chatInputTextArea {
  outline-style: none;
  background-color: white;
}

.zoom {
  padding: 50px;
  background-color: green;
  transition: transform .2s; /* Animation */
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.chatSendButton {
  position: absolute; 
  right: 0.75rem;
  top: 1rem;
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  .zoom:hover {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.5);
  }
}

.aiResponseStyle {
  color: #140202; 
  --bg-opacity: 0.75;
  background: #e9ebeb;
  margin-right: 90px;
  width:820px;
}

.userStyle {
  color: #070707; 
  --bg-opacity: 0.75;
  background: #9ceeee;
  margin-left: 60px;
}

.citations {
  color: #a20b0b; 
  --bg-opacity: 0.75;
  margin-left: 50px;
}

.chatBodyDiv {
  display: flex; 
  flex-direction: column;
  gap: 1rem;
}

.chatBodyText {
  padding: 0.75rem;
  border-radius: 0.75rem;
  border-width: 2px;
  overflow-wrap: break-word;
  border-color: #636362;
  max-width: 100%;
  font-size: 14px;
}

.citationBodyText {
  font-size: 14px;
  padding-left: 0.05rem;
  overflow-wrap: break-word; 
  border-color: #636362;
  max-width: 1000%;
  top: auto
  }

.wrapWhiteSpace {
  white-space: pre-wrap;
  font-family: "Nunito Sans", sans-serif;
}

.left-element {
  background: rgb(225, 235, 245);
  display: inline-block;
  width: 840px;
  float: left;
}

.right-element {
  float:left;
  background: rgb(225, 235, 245) no-repeat;
  width: 380px;
  display: inline-block;
}