.base-btn {
  background-color: #008385 !important;
}

.MuiDataGrid-columnHeaders {
  background-color: #f6f6f6; /* Set your desired background color here */
  /* border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0% !important; */
}

.editBtn-new .material-icons {
  color: black !important;
}
