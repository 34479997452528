.enterprise-box {
  border: 1px solid #cbcbcb;
  border-radius: 6px;
  padding: 20px;
}

.enterprise-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.myroaster {
  height: 50px;
}

.enterprise-row .title {
  color: black;
  font-weight: bold;
  width: 180px;
}
