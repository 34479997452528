.residentDetailTab {
  box-shadow: var(--tealBoxShadow) !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  border-radius: 10px 10px 0px 0px !important;
  background: rgba(203, 221, 221, 0.2) !important;
  padding: 10px 10px !important;
  min-height: 0 !important;
  text-transform: none !important;
}

.mealCardTabs {
  box-shadow: var(--tealBoxShadow) !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 14px !important;
  line-height: 15px !important;
  border-radius: 10px 10px 0px 0px !important;
  background: rgba(203, 221, 221, 0.2) !important;
  padding: 10px 10px !important;
  min-height: 0 !important;
  text-transform: none !important;
}
