.resourceMain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
}

.resourceButton {
  border: 1px solid #ededed;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 196px;
  width: 282px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.resourceButton .material-icons {
  color: var(--tealColor);
  text-align: center;
  width: 30px;
}

.resourceDietMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.downloadBtn {
  color: #eb3223 !important;
  border: 1px solid #eb3223 !important;
  background-color: white !important;
  border-radius: 10px !important;
}

.isresourceBarRow {
  border: 1px solid #00bcbe !important;
  background-color: white !important;
  margin-top: 0px !important;
  border-bottom-color: #ededed !important;
}

.resourceBarRow {
  border: 1px solid #ededed !important;
  background-color: #f8f9fa !important;
  margin-top: 0px !important;
}

.resourceBarContainer {
  border: 1px solid #00bcbe !important;
  border-top: none !important;
}

.dropdownBox {
  border: 1px solid #ededed;
  background-color: #f8f9fa;
  width: 90%;
  height: 40px;
  border-radius: 10px;
  padding: 5px;
  font-weight: 700;
  color: black;
  text-decoration: none;
  margin-top: 10px !important;
}

.communityInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #636363;
  background-color: #fcfcfc;
  color: "#000000";
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px;
}

.title {
  font-weight: 700 !important;
  color: black;
}

.iscommunityInfo {
  border-bottom-width: 90%;
  border-radius: 10px 10px 0px 0px;
}

.communityInfo .material-icons {
  line-height: 0px;
  font-size: 50px;
}

.communityContainer {
  border: 1px solid #636363;
  border-top: none;
  padding: 50px;
  border-radius: 0px 0px 10px 10px;
}

/* new Css code */

.resourcemain {
  margin: 30px;
}

.resourcemainli {
  list-style-type: disc !important;
  display: list-item;
  font-size: 32px !important;
  font-weight: 700 !important;
}

.resourcemainli a:hover {
  color: var(--tealColor);
}
