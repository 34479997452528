.residentTitle {
  font-size: 24px;
  font-weight: 700;
}

.residentBodyDataLabel {
  font-size: 16px;
  font-weight: 700;
}

.residentBodyDataValue {
  font-size: 16px;
  font-weight: normal;
}

.dietBodyDataLabel {
  font-size: 16px;
  font-weight: 700;
}

.dietBodyDataValue {
  font-size: 16px;
  font-weight: normal;
}

.MuiTableCell-root {
  border: 2px #636363 solid !important;
}

.residentDetailsPrint {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 10px;
  margin-bottom: 20px;
  border: 1px solid #d9d9d9;
  background-color: #546E7A;
  color: white;
  font-size: 18px;
  font-weight: 700;
  min-width: 12.188rem;
  padding: 0rem 1rem;
  border-radius: 4px;
}
