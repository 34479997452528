.container {
  /* white */
  background: var(--whiteColor);

  /* sha */
  box-shadow: var(--tealBoxShadow) !important;
  border-radius: var(--borderRadius) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-header {
  background-color: white;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  width: 130px;
  padding: 9px 10px;
}

.order-transmission {
  margin-left: 30px;
  background-color: white;
  width: 15%;
  padding: 7px;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
}

.print-item-header {
  display: flex;
  margin-left: 30px;
  background-color: white;
  padding: 7px;
  width: 47%;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
}

.add-new-menu {
  width: 20% !important;
}

.recipes-tab {
  text-decoration: none;
  font-weight: 700 !important;
  width: 120px;
  padding: 9px 10px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  background-color: rgba(211, 211, 211, 0.3);
  color: gray;
}

.menu-row {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 100%;
}

.menu-row .MuiFormControl-root {
  width: 350px;
}

.menu-row .basicInput {
  padding: 28px 5px !important;
  border-radius: 5px !important;
}

.btn-share {
  background-color: #e3fafb !important;
  color: #55c2c5 !important;
  border: 2px solid #55c2c5 !important;
  width: 48%;
}

.btn-remove {
  background-color: #fdf0f0 !important;
  color: #fa786d !important;
  border: 2px solid #fa786d !important;
  width: 48%;
}

.main-body {
  background-color: white;
  padding: 10px;
  margin: 0 30px 30px;
}

.main-body form {
  margin-top: 40px;
}

.print-heading {
  padding: 10px;
  width: 97%;
}

.print-title-box {
  padding: 10px;
  color: #55c2c5;
  border: 1px solid #55c2c5;
  border-radius: 5px;
  margin: 10px !important;
  margin-bottom: 40px !important;
}

.print-item .MuiFormGroup-root {
  flex-direction: column;
}

.print-item .MuiFormControl-root {
  margin-bottom: 10px;
}

.Row {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  width: 100%;
  padding-bottom: 10px;
  margin-right: 5px;
  margin-left: -20px !important;
}

.baseButton {
  margin-left: 10px !important;
}

.menuPlannerButton {
  margin-left: 10px !important;
  /* margin-right: 25px !important; */
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  /* identical to box height */
  padding: 10px 20px !important;
  text-transform: none !important;
  border-radius: 10px !important;
}

.menuActionButtons {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
}

.plateMenuButton {
  margin-top: 4px;
  color: var(--yellowColor);
}

.plateMenuButton:focus {
  color: var(--yellowColor) !important;
}

.recipe-desc {
  color: #55c2c5;
  border: 1px solid #55c2c5;
  padding: 5px;
  margin: 5px !important;
  width: 70%;
}

.emptyVendorItemStyle {
  background-color: lightpink;
}
