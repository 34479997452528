.MuiCardHeader-content {
  color: white;
  background-color: "#1A4650";
}

.MuiPaper-root {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.text-color {
  color: white;
}
